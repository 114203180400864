import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Navbar/Header";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes";
import { NotificationProvider } from "./context/NotificationContext";

const App = () => {
  return (
    <Router>
      {/* <Header /> */}
      <ToastContainer />
      <div className="main-content">
        {/* <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute>
                <SearchScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/summarize"
            element={
              <ProtectedRoute>
                <Summarize />
              </ProtectedRoute>
            }
          />
         
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/*" element={<Error />} />
        </Routes> */}
        <NotificationProvider>
        <AppRoutes />
        </NotificationProvider>
      </div>
    </Router>
  );
};

export default App;
