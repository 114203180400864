import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./header.css";
import usericon from "../../assets/Avatar.png";
import notificationbell from "../../assets/carbon_notification-filled.png";
import { logout } from "../../redux/features/user/userSlice";
import { useNotification } from "../../context/NotificationContext";
import Notification from "../notifications/Notification"; 
import gcslogo from '../../assets/gcs-legestative-logo.png'

const Header = () => {
  const { addNotification, clearAllNotifications } = useNotification();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.user);
  const { name, profile_picture } = userData || {};

  const [showNotification, setShowNotification] = useState(false); // State to control notification visibility
  const navigate = useNavigate();
  let isUserAuthenticated = JSON.parse(localStorage.getItem("auth"));

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleShowNotification = () => {
    addNotification("Alert notification");
    setShowNotification(true); 
  };

  const handleClearAll = () => {
    clearAllNotifications(() => setShowNotification(false)); // Clear notifications and close panel
  };

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false); // Auto-hide the notification after 5 seconds
      }, 5000);

      // Cleanup the timer if the component unmounts or notification is hidden
      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={gcslogo} alt="" />
          </a>
          <button
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              {/* <Link className="nav-link active" aria-current="page" to="/home">
                Home
              </Link> */}
              {!isUserAuthenticated && (
                <Link className="nav-link" to="/">
                  Login/SignUp
                </Link>
              )}
              {/* <Link className="nav-link" to="/dashboard">
                Dashboard
              </Link> */}
              {/* <Link className="nav-link" to="/search">
                Search
              </Link> */}
            </div>
            <div className="ms-auto d-flex align-items-center">
              {/* <Link to="/" className="notification-icon me-3">
                <img src={notificationbell} alt="Notifications" height="21" />
              </Link> */}
              {isUserAuthenticated?.token && (
                <div className="d-flex align-items-center">
                  <Link to="/profile" className="user-icon circular--portrait">
                    <img
                      src={profile_picture ? profile_picture : usericon}
                      alt="User Profile"
                      width="35"
                      height="35"
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Pass isVisible prop to Notification component */}
      <Notification isVisible={showNotification} handleClearAll={handleClearAll} />
    </div>
  );
};

export default Header;
